@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 222.2 47.4% 11.2%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 47.4% 11.2%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 47.4% 11.2%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 100% 50%;
		--destructive-foreground: 210 40% 98%;

		--ring: 215 20.2% 65.1%;

		--radius: 0.5rem;
	}

	.dark {
		--background: 224 71% 4%;
		--foreground: 213 31% 91%;

		--muted: 223 47% 11%;
		--muted-foreground: 215.4 16.3% 56.9%;

		--popover: 224 71% 4%;
		--popover-foreground: 215 20.2% 65.1%;

		--card: 224 71% 4%;
		--card-foreground: 213 31% 91%;

		--border: 216 34% 17%;
		--input: 216 34% 17%;

		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 1.2%;

		--secondary: 222.2 47.4% 11.2%;
		--secondary-foreground: 210 40% 98%;

		--accent: 216 34% 17%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 63% 31%;
		--destructive-foreground: 210 40% 98%;

		--ring: 216 34% 17%;

		--radius: 0.5rem;
	}
}

@layer base {
	* {
		@apply border-border;
	}

	html {
		scroll-behavior: smooth;
	}
	
	body {
		@apply bg-background text-foreground;
		font-feature-settings: "rlig" 1, "calt" 1;

		/*needed to push content back to top when hiding google translate top bar */
		top:0 !important;
	}

}

@layer components{
	/*legal/page.tsx*/

	ol#l1 {
		counter-reset: section;
	}

	  ol#l1 li h4::before {
		counter-increment: section;
		content: counter(section) ". ";
	  }






	/*Home Search Options, keeps firefox from using default times new roman font for all options*/
	@-moz-document url-prefix() {
		option{

			font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

			font-weight: 400;

		}



	}

	/*keeps home search bar background icon from hugging margin, problems when set with tailwind*/
	.homeSearchIcon{
		background-position: 1rem 50%;
	}

	.review-form .rating > input:checked ~ label, /* show gold star when clicked */
	.review-form .rating:not(:checked) > label:hover, /* hover current star */
	.review-form .rating:not(:checked) > label:hover ~ label {

		color: #ffd700;
	} /* hover previous stars in list */

	.review-form .rating > input:checked + label:hover, /* hover current star when changing rating */
	.review-form .rating > input:checked ~ label:hover,
	.review-form .rating > label:hover ~ input:checked ~ label, /* lighten current selection */
	.review-form .rating > input:checked ~ label:hover ~ label {

		color: #ffed85;
	}




	.embla__container{
		backface-visibility: hidden;

	}

	/*Used for the calendar popup of the admin date picker*/
	.rdp-vhidden {
		@apply hidden;
	}

	/*Cannot be targeted with tailwind*/
	.admin-video-card[poster]{
		width: 100%;
		height: 100%;
		object-fit: cover;

	}

	#google_translate_element1{

		position: relative;
		display: block;
		padding-left: 45px;
		bottom: -4px;
		margin-right: 24px;
	}



	#google_translate_element1 select{

		height: 32px;

		border-radius: 5px 5px 0px 0px;
		padding: 0 20px;
		font-weight: bold;
		font-size: 14px;
		color: black;
	}



	#google_translate_element1 a{

		align-items: center;
		gap: 3px;
	}

	/*hide google translate thing at top of page*/
	iframe#\:1\.container{
		display: none !important;
	}

	#google_translate_element1 > div {
		display: flex;
		flex-direction: row-reverse;
		font-size: 0;
	}


}

@layer utilities {

	@-moz-document url-prefix(){
		/*Firefox*/
		.styledScrollbar{
			scrollbar-color: #d3d2d2 white;
			scrollbar-width: thin;
		}
	}

	

	/*Chrome Safari*/
	.styledScrollbar::-webkit-scrollbar {
	  width: 8px;
	  height: 8px;

	}

	.styledScrollbar::-webkit-scrollbar-thumb {
	  background: #d3d2d2;
	  border-radius: 100px;

	}


}



@layer utilities {

	@-moz-document url-prefix(){
		.dialogScrollbar{
			scrollbar-color: #d3d2d2 white;
			scrollbar-width: thin;
		}
	}
	

	/*Chrome Safari*/
	.dialogScrollbar::-webkit-scrollbar {
	  width: 8px;
	  height: 8px;


	}

	.dialogScrollbar::-webkit-scrollbar-thumb {
	  background: #d3d2d2;
	  border-radius: 100px;

	}


}



.coming-soon-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	text-align: center;
	background-color: #f2f5f8;
	color: #394049;
}

.coming-soon-logo {
	width: 300px;
	height: auto;
	margin-bottom: 2rem;
}

.coming-soon-title {
	font-size: 3rem;
	margin-bottom: 1rem;
	font-weight: bold;
	color: #335080;
}

.coming-soon-subtitle {
	font-size: 1.5rem;
	margin-bottom: 2rem;
}